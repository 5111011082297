const CONFIG_SCHEMA_REQUEST = "CONFIG_SCHEMA_REQUEST";
const CONFIG_SCHEMA_REQUEST_SUCCESS = "CONFIG_SCHEMA_REQUEST_SUCCESS";
const CONFIG_SCHEMA_REQUEST_FAIL = "CONFIG_SCHEMA_REQUEST_FAIL";
const CONFIG_DATA_REQUEST = "CONFIG_DATA_REQUEST";
const CONFIG_DATA_REQUEST_SUCCESS = "CONFIG_DATA_REQUEST_SUCCESS";
const CONFIG_DATA_REQUEST_FAIL = "CONFIG_DATA_REQUEST_FAIL";
const CONFIG_DATA_POST_REQUEST = "CONFIG_DATA_POST_REQUEST";
const CONFIG_DATA_POST_REQUEST_SUCCESS = "CONFIG_DATA_POST_REQUEST_SUCCESS";
const CONFIG_DATA_POST_REQUEST_FAIL = "CONFIG_DATA_POST_REQUEST_FAIL";
const CONFIG_SCHEMA_CLEAR_REQUEST = "CONFIG_SCHEMA_CLEAR_REQUEST";
const CONFIG_DATA_CLEAR_REQUEST = "CONFIG_DATA_CLEAR_REQUEST";
const SET_CONFIG_TREE = "SET_CONFIG_TREE";
const CLEAR_CONFIG_TREE = "CLEAR_CONFIG_TREE";
const SET_CONFIG_TREE_SELECTED_ITEM = "SET_CONFIG_TREE_SELECTED_ITEM";
const CLEAR_CONFIG_TREE_SELECTED_ITEM = "CLEAR_CONFIG_TREE_SELECTED_ITEM";
const UPDATE_CONFIG_DATA = "UPDATE_CONFIG_DATA";
const CLEAR_CONFIG_FORM_ERROR_MESSAGE = "CLEAR_CONFIG_FORM_ERROR_MESSAGE";
const CLEAR_CONFIG_FORM_FIELDSET = "CLEAR_CONFIG_FORM_FIELDSET";
const DISPATCHING_IS_VALID_DJR = "DISPATCHING_IS_VALID_DJR"

const dispatchingIsValidDJR = isValid => ({
  type: DISPATCHING_IS_VALID_DJR,
  payload: isValid
})

const fetchConfigSchema = componentId => ({
  type: CONFIG_SCHEMA_REQUEST,
  payload: componentId,
});

const clearConfigSchema = () => ({
  type: CONFIG_SCHEMA_CLEAR_REQUEST,
});

const fetchConfigData = params => ({
  type: CONFIG_DATA_REQUEST,
  payload: params,
});

const clearConfigData = () => ({
  type: CONFIG_DATA_CLEAR_REQUEST,
});

const postConfigData = data => ({
  type: CONFIG_DATA_POST_REQUEST,
  payload: data,
});

const updateConfigData = data => ({
  type: UPDATE_CONFIG_DATA,
  payload: data,
});

const setConfigTree = payload => ({
  type: SET_CONFIG_TREE,
  payload,
});

const clearConfigTree = () => ({
  type: CLEAR_CONFIG_TREE,
});

const setSelectedTreeItem = payload => ({
  type: SET_CONFIG_TREE_SELECTED_ITEM,
  payload,
});

const clearSelectedTreeItem = () => ({
  type: CLEAR_CONFIG_TREE_SELECTED_ITEM,
});

const clearConfigErrorMessage = () => ({
  type: CLEAR_CONFIG_FORM_ERROR_MESSAGE,
});

const clearConfigFormFieldSet = () => ({
  type: CLEAR_CONFIG_FORM_FIELDSET,
});

export {
  CONFIG_SCHEMA_REQUEST,
  CONFIG_SCHEMA_REQUEST_SUCCESS,
  CONFIG_SCHEMA_REQUEST_FAIL,
  CONFIG_DATA_REQUEST,
  CONFIG_DATA_REQUEST_SUCCESS,
  CONFIG_DATA_REQUEST_FAIL,
  CONFIG_DATA_POST_REQUEST,
  CONFIG_DATA_POST_REQUEST_SUCCESS,
  CONFIG_DATA_POST_REQUEST_FAIL,
  CONFIG_SCHEMA_CLEAR_REQUEST,
  CONFIG_DATA_CLEAR_REQUEST,
  UPDATE_CONFIG_DATA,
  SET_CONFIG_TREE,
  CLEAR_CONFIG_TREE,
  SET_CONFIG_TREE_SELECTED_ITEM,
  CLEAR_CONFIG_TREE_SELECTED_ITEM,
  CLEAR_CONFIG_FORM_ERROR_MESSAGE,
  CLEAR_CONFIG_FORM_FIELDSET,
  fetchConfigSchema,
  clearConfigSchema,
  fetchConfigData,
  clearConfigData,
  postConfigData,
  updateConfigData,
  setConfigTree,
  clearConfigTree,
  setSelectedTreeItem,
  clearSelectedTreeItem,
  clearConfigErrorMessage,
  clearConfigFormFieldSet,
  dispatchingIsValidDJR,
  DISPATCHING_IS_VALID_DJR
};
