import React, { useRef,memo,useState,useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import store from "store/configureStore";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { debounce } from "lodash";
import { setCurrentPage } from "actions/applicationsActions";
import { connect } from "react-redux";
import { dispatchingIsValidDJR } from "actions/configFormActions";

const AppEditCode = ({ appBarHeight, toolBarHeight, djr, setDjr, dispatchIsValidDJR}) => {
  const inputEl = useRef(null);
  const [annotations, setAnnotations] = useState([]);
  const [djrvalue, updateDjrValue] = useState(djr);
  
  useEffect(() => {
    updateDjrValue(djr)
  },[djr]);
  
  const debouncedOnChange = debounce(onChange, 800);

  const validateJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      setAnnotations([]);
    } catch (error) {
      const errorMessage = error.message;

      const positionMatch = errorMessage.match(/position (\d+)/);
      const position = positionMatch ? parseInt(positionMatch[1], 10) : -1;
  
      if (position !== -1) {
        const lines = jsonString.slice(0, position).split("\n");
        const row = lines.length - 1;
        const column = lines[row].length;
  
        setAnnotations([
          {
            row,
            column,
            text: errorMessage,
            type: "error",
          },
        ]);
      } else {
        setAnnotations([
          {
            row: 0,
            column: 0,
            text: "Invalid JSON",
            type: "error",
          },
        ]);
      }
    }
  };

  function onChange(newDjr) {
      updateDjrValue(newDjr);
      validateJson(newDjr);
      try{
        const jsonDjr = JSON.parse(newDjr);
        setDjr(newDjr);
        const { applications } = store.getState();
        const currentPage =  applications?.current?.currentPage;
        store.dispatch(setCurrentPage({ ...currentPage, jsonDjr }))
        dispatchIsValidDJR(true)
      }catch(error){
        dispatchIsValidDJR(false)
      }
  }
  return (
    <AceEditor
      style={{
        height: `calc(100vh - ${appBarHeight}px - ${toolBarHeight}px)`,
        width: "100%",
      }}
      mode="json"
      theme="github"
      onChange={debouncedOnChange}
      value={djrvalue}
      name="json-editor"
      ref={inputEl}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        useWorker: false
      }}
      annotations={annotations}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchIsValidDJR: (isValidDjr) => dispatch(dispatchingIsValidDJR(isValidDjr)),
});

export default connect(null, mapDispatchToProps)(memo(AppEditCode));
