/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable func-call-spacing */
import React, { useEffect, forwardRef, useState, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { isEmpty } from "lodash";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Dialog, DialogContent, Drawer, Grid, Slide, Snackbar, TextField } from "@material-ui/core";
import { fetchNexusListWidgets, setNexusWidgetView, clearNexusWidgetsList  } from "actions/nexusWidgetsActions";
import {
  setShowAddPage as setShowAddPageAction,
  setCurrentPage as setCurrentPageAction,
  setShowAddTemplate,
  savePage,
  showPages,
  showSideBar,
  showCreatePage,
  showSnackbar,
  fetchApps,
  clearSelectedWidget,
  clearCurrRowCol,
  setAppSidebar,
  setApplicationSection,
  saveAppVersion,
  resolvePublicPackage,
} from "actions/applicationsActions";
import { parseModule } from "shift-parser";
import AppHeader from "./AppDialogHeader";
import theme from "../../AppTheme";
import AppDialogStarter from "./AppDialogStarter";
import AppsSidebar from "./AppsSidebar";
import VfAlert from "../vf/VfAlert";
import VfDialog from "../vf/VfDialog";
import AppsPageAdd from "./AppsPageAdd";

import {
  setApp,
  updateSnippet,
  setNameOfSaga,
  removeSaga,
  addNewSaga,
  addNewApi,
  editApi,
  removeApi,
  addNewPackage,
  editPackage,
  removePackage,
  addNewSnippet,
  removeSnippet,
} from "../../actions/applicationsActions";
import AppsDialogSectionAppBar from "./AppsDialogSectionAppBar";
import AddApiDialog from "./AddApiDialog";
import AddPackageDialog from "./PackageDialog";
import SelectApiVersionDialog from "./SelectApiVersionDialog";
import store from "../../store/configureStore";

const appBarHeight = theme.spacing(13);

const useStyles = makeStyles({
  gridContainer: {
    minHeight: "100%",
    position: "relative",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    paddingLeft: theme.spacing(42),
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  dialogContent: {
    padding: 0,
  },
  drawer: {
    width: theme.spacing(42),
    flexShrink: 0,
  },
  drawerPaper: {
    top: theme.spacing(13),
    width: theme.spacing(42),
    flexShrink: 0,
    zIndex: 2,
    overflow: "visible",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[100],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    zIndex: theme.zIndex.appBar - 10,
  },
  iconButton: {
    "& .MuiSvgIcon-root": {
      position: "relative",
      transition: theme.transitions.create(["transform"], {
        duration: theme.transitions.duration.standard,
      }),
    },
  },
  iconButtonRotate: {
    "& .MuiSvgIcon-root": {
      transform: "rotate(180deg)",
    },
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppsDialog = ({
  auth,
  fetchNexusWidgets,
  fetchTenantWidgets,
  showApp,
  show,
  clearApplication,
  application,
  environment,
  clearApplicationEnv,
  showCreateApp,
  setGridListView,
  setShowAddPage,
  setCurrentPage,
  setAppSection,
  dispatchSavePage,
  dispatchShowPages,
  dispatchshowSideBar,
  dispatchShowCreatePage,
  dispatchShowAddTemplate,
  dispatchShowSnackBar,
  snackBar,
  getApplications,
  clearNexusWidgets,
  dispatchAddNewSaga,
  dispatchSetNameForSaga,
  dispatchRemoveSaga,
  dispatchAddNewApi,
  dispatchAddNewPackage,
  dispatchEditApi,
  dispatchEditPackage,
  dispatchRemoveApi,
  dispatchAddNewSnippet,
  dispatchRemovePackage,
  dispatchEditSnippet,
  dispatchRemoveSnippet,
  dispatchSaveAppVersion,
  clearWidget,
  clearRowCol,
  sidebarItem,
  setSidebarItem,
  isLoading,
  setApplication,
  categoryWidgets,
  apis,
  packages,
  isDeployment,
  widgetsUpdating,
}) => {
  const classes = useStyles();
  const { access_token, id_token } = auth;

  useEffect(() => {
    if (!widgetsUpdating) {
      fetchNexusWidgets({ envID: null, access_token, id_token,widgetsIds:application.appInfo.widgets.map(wi => wi._id) });
    }
  }, [widgetsUpdating]);

  useEffect(() => {
    if (application.pages.length < 1) dispatchshowSideBar(false);
    if (application.pages.length && isEmpty(application.currentPage)) {
      const homePage = application.pages.filter(p => p.isHomePage)[0];
      if (homePage) {
        const { data, ...rest } = homePage;
        setCurrentPage({
          djr: data,
          pageInfo: rest,
          spacing: 3,
        });
        if (isDeployment) {
          setSidebarItem("widgetEditor");
          dispatchshowSideBar(false);
        }
        dispatchshowSideBar(true);
        setShowAddPage(true);
        dispatchShowAddTemplate(true);
      }
    }
  }, [application.pages]);

  const [page, setPageValues] = useState({
    title: "",
    documentTitle:"",
    description: "",
    tags: [],
    isHomePage: !application?.pages?.length > 0,
    application: application?.appInfo?._id,
    private: false,
    isMicroFrontend: false,
    isGenericPage: false,
    isSharedPage:false,
    baseRoute: "",
  });

  useEffect(() => {
    if (application.currentPage.pageInfo)
      setPageValues({
        ...application.currentPage.pageInfo,
      });
    else
      setPageValues({
        title: "",
        documentTitle:"",
        description: "",
        tags: [],
        isHomePage: !application.pages.length > 0,
        application: application.appInfo._id,
        private: false,
        isMicroFrontend: false,
        isGenericPage: false,
        baseRoute: "",
      });
  }, [application.showCreatePage]);

  const handleClose = () => {
    showApp(false);
    getApplications({ access_token, id_token });
    clearWidget();
    clearApplication();
    clearApplicationEnv();
    clearNexusWidgets();
  };

  useEffect(() => {
    return () => {
      showApp(false);
      clearWidget();
      clearApplication();
      clearApplicationEnv();
      clearNexusWidgets();
    };
  }, []);

  const getChangedPages = changedPages => {
    return (
      Object.values(changedPages)
        // eslint-disable-next-line no-shadow
        .filter(page => !!page)
        // eslint-disable-next-line no-shadow
        .map(page => ({
          ...page.pageInfo,
          _id: page.pageInfo._id.indexOf("-") >= 0 ? undefined : page.pageInfo._id,
          data: page.djr,
        }))
    );
  };

  const isPageValid = (page, application) => {
    const pagesTitles = application?.pages?.map(page => page?.title);
  
    const currentPageTitle = page?.title;
    const currentDocumentTitle = page?.documentTitle;
  
    const existingPage = application?.pages?.find(p => p.title === currentPageTitle);
  
    if (!currentPageTitle?.match("^[a-z0-9-]+$")) return false;
  
    const pageFound = pagesTitles?.find(title => title === currentPageTitle);
  
    if (pageFound && (existingPage && existingPage.documentTitle === currentDocumentTitle)) return false;
  
    if (page.title.length < 1 && page.documentTitle.length < 1) return false;
  
    if (page.isMicroFrontend && !(page.baseRoute || "").match("^/[a-zA-Z0-9]+$")) {
      return false;
    }
  
    return true;
  };
  const handleSave = () => {
    const {
      applications: { current: application },
    } = store.getState(); // To get the most updated state after the previos action was dispatched
    dispatchSavePage({
      access_token,
      id_token,
      pages: getChangedPages(application.changedPages),
      application: application.appInfo,
      envId: environment.id,
    });
  };

  const handleSaveVersion = (version, description) => {
    dispatchSaveAppVersion({
      access_token,
      id_token,
      pages: getChangedPages(application.changedPages),
      application: { ...application.appInfo, version, description },
    });
  };

  const handleEdit = () => {
    showCreateApp(true);
  };

  const [sagaId, setSagaId] = useState(null);
  const [dialogLogic, setDialogLogic] = useState(false);
  const sagaNameForId = sagaId ? application.appInfo.sagas.find(s => s.id === sagaId).name : "";
  const [sagaName, setSagaName] = useState(sagaNameForId);
  useEffect(() => setSagaName(sagaNameForId), [sagaId]);

  const handleAddNewSaga = name => dispatchAddNewSaga(name);

  const handleSetNameForSaga = (id, name) => dispatchSetNameForSaga(id, name);

  const handleRemoveSaga = id => {
    dispatchRemoveSaga(id);
    setSagaId(null);
  };

  const [dialogApi, setDialogApi] = useState(false);
  const [dialogPackage, setDialogPackage] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [apiId, setApiId] = useState(null);
  const apiNameForId = apiId ? (application.appInfo.apis ?? []).find(s => s._id === apiId)?.name : "";
  const [editApiVersion, setEditApiVersion] = useState(false);
  const [editPackageVersion, setEditPackageVersion] = useState(false);
  const [djr, setDjr] = useState(null);

  const handleAddNewApi = data => dispatchAddNewApi(data);
  const handleAddNewPackage = pack => dispatchAddNewPackage(application.appInfo._id, pack);
  const handleEditApi = data => dispatchEditApi(data);
  const handleEditPackage = data => dispatchEditPackage(data);

  const handleRemoveApi = id => {
    dispatchRemoveApi(id);
  };

  const handleRemovePackage = id => {
    dispatchRemovePackage(id);
  };
  /* Javascript Snippet Start */
  const [dialogSnippet, setDialogSnippet] = useState(false);
  const [snippetId, setSnippetId] = useState(null);
  const selectedSnippet = useMemo(() => application.appInfo.snippets?.find(s => s.id === snippetId) || {}, [snippetId]);
  const snippetNameFromId = selectedSnippet.name || "";
  const [snippetName, setSnippetName] = useState(snippetNameFromId);
  const [snippetCode, setSnippetCode] = useState(selectedSnippet.javascriptString);

  useEffect(() => {
    setSnippetCode(selectedSnippet.javascriptString);
  }, [snippetId]);

  const handleAddNewSnippet = name => dispatchAddNewSnippet(name);

  const handleSetNameForSnippet = (id, name) => dispatchEditSnippet(id, { ...selectedSnippet, name });

  const debouncedUpdateSnippet = (id, code) => {
    let parseError;
    let defaultExportBody;
    let functionFound;
    let arrowVariableFound;
    try {
      const ast = parseModule(code);
      defaultExportBody = ast.items?.find(item => item.type === "ExportDefault")?.body || null;
      if (defaultExportBody) {
        const functionName = defaultExportBody.name;
        functionFound = ast.items?.find(
          item =>
            item.type === "FunctionDeclaration" &&
            item.name.type === "BindingIdentifier" &&
            item.name.name === functionName
        );
        arrowVariableFound = ast.items?.find(
          item =>
            item.type === "VariableDeclarationStatement" &&
            item.declaration.declarators[0].binding.type === "BindingIdentifier" &&
            item.declaration.declarators[0].binding.name === functionName &&
            item.declaration.declarators[0].init.type === "ArrowExpression"
        );
      }
      parseError = null;
    } catch (e) {
      defaultExportBody = null;
      parseError = e;
    }
    if (parseError) {
      dispatchShowSnackBar({
        show: true,
        message: `Parse error: ${parseError}`,
        severity: 0,
      });
    } else if (!defaultExportBody) {
      dispatchShowSnackBar({
        show: true,
        message: "No default export found",
        severity: 0,
      });
    } else if (!functionFound && !arrowVariableFound) {
      dispatchShowSnackBar({
        show: true,
        message: `No function ${defaultExportBody.name} found`,
        severity: 0,
      });
    } else {
      dispatchShowSnackBar({ show: false });
    }
    dispatchEditSnippet(id, {
      ...selectedSnippet,
      javascriptString: code,
      data: defaultExportBody,
    });
  };

  const handleUpdateSnippet = (id, code) => {
    setSnippetCode(code);
    debouncedUpdateSnippet(id, code);
  };

  const handleRemoveSnippet = id => {
    dispatchRemoveSnippet(id);
    setSnippetName("");
    setSnippetId(null);
  };
  /* Javascript Snippet End */

  const handleSaveNameChange = curPage => {
    dispatchSavePage({
      access_token,
      id_token,
      pages: getChangedPages({
        ...application.changedPages,
        [curPage.pageInfo._id]: curPage,
      }),
      application: application.appInfo,
      envId: environment.id,
    });
  };

  const isLogicApiPckSnipSelected = id => {
    if (sidebarItem === "apiList") return id === apiId;
    if (sidebarItem === "packagesList") return id === packageId;
    if (sidebarItem === "javascript") return id === snippetId;
    if (sidebarItem === "appLogicList") return id === sagaId;
    return false;
  };

  return (
    <>
      <Dialog
        fullScreen
        open={show}
        onClose={() => {
          showApp(false);
          getApplications({ access_token, id_token });
          clearApplication();
          clearApplicationEnv();
        }}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <AppHeader
          application={application}
          showCreateApp={showCreateApp}
          onClose={handleClose}
          onSave={handleSave}
          onSaveVersion={handleSaveVersion}
          onEdit={isDeployment ? undefined : handleEdit}
        />

        <DialogContent className={classes.dialogContent}>
          <DndProvider backend={HTML5Backend}>
            <Drawer
              open={application.showSidebar}
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <AppsSidebar
                callback={fetchNexusWidgets}
                closeSidebar={dispatchshowSideBar}
                setGridListView={setGridListView}
                sidebarItem={sidebarItem}
                setSidebarItem={setSidebarItem}
                application={application}
                dispatchShowPages={dispatchShowPages}
                dispatchShowAddTemplate={dispatchShowAddTemplate}
                setShowAddPage={setShowAddPage}
                setDialogLogic={setDialogLogic}
                setSagaId={setSagaId}
                handleRemoveSaga={handleRemoveSaga}
                setAppSection={setAppSection}
                setDialogApi={setDialogApi}
                setApiId={setApiId}
                setPackageId={setPackageId}
                setDialogApiVersion={setEditApiVersion}
                handleRemoveApi={handleRemoveApi}
                setDialogPackage={setDialogPackage}
                handleRemovePackage={handleRemovePackage}
                setDialogPackageVersion={setEditPackageVersion}
                setDialogSnippet={setDialogSnippet}
                setSnippetId={setSnippetId}
                handleRemoveSnippet={handleRemoveSnippet}
                isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
              />
            </Drawer>
            <Box
              className={classNames(classes.gridContainer, {
                [classes.contentShift]: application.showSidebar,
              })}
            >
              <AppsDialogSectionAppBar
                sidebarItem={sidebarItem}
                clearWidget={clearWidget}
                clearRowCol={clearRowCol}
                application={application}
                dispatchshowSideBar={dispatchshowSideBar}
                setSidebarItem={setSidebarItem}
                setCurrentPage={setCurrentPage}
                setShowAddPage={setShowAddPage}
                dispatchShowPages={dispatchShowPages}
                dispatchShowAddTemplate={dispatchShowAddTemplate}
                sagaName={sagaNameForId}
                apiName={apiNameForId}
                snippetName={snippetNameFromId}
                setSnippetId={setSnippetId}
                setSagaId={setSagaId}
                setAppSection={setAppSection}
                setDjr={setDjr}
                djr={djr}
                setApplication={setApplication}
                categoryWidgets={categoryWidgets}
                showSnackBar={dispatchShowSnackBar}
                dispatchShowCreatePage={dispatchShowCreatePage}
              />
              <Box position="relative" zIndex={1}>
                <Grid container>
                  <Grid item xs={12}>
                    {!isLoading && (
                      <AppDialogStarter
                        application={application}
                        dispatchShowCreatePage={dispatchShowCreatePage}
                        appBarHeight={appBarHeight}
                        setSidebarItem={setSidebarItem}
                        sidebarItem={sidebarItem}
                        sagaId={sagaId}
                        apiId={apiId}
                        snippetId={snippetId}
                        packageId={packageId}
                        updateSnippet={handleUpdateSnippet}
                        snippetCode={snippetCode}
                        djr={djr}
                        setDjr={setDjr}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DndProvider>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackBar.show}
            onClose={() => dispatchShowSnackBar({ ...snackBar, show: false })}
          >
            <VfAlert severity={snackBar.severity} message={snackBar.message} />
          </Snackbar>
          <VfDialog
            title={!isEmpty(application.currentPage) ? "Save Page" : "Add page"}
            openDialog={application.showCreatePage}
            buttonConfirmText={!isEmpty(application.currentPage) ? "Save" : "Add page"}
            buttonCloseAction={() => dispatchShowCreatePage(false)}
            buttonConfirmDisabled={!isPageValid(page, application)}
            buttonConfirmAction={() => {
              dispatchShowCreatePage(false);
              setTimeout(() => {
                if (isEmpty(application.currentPage)) {
                  setSidebarItem("widgetList");
                  setShowAddPage(true);
                  setCurrentPage({
                    ...application.currentPage,
                    pageInfo: page,
                  });
                  setAppSection("design");
                } else {
                  setCurrentPage({
                    ...application.currentPage,
                    pageInfo: page,
                  });
                  handleSaveNameChange({
                    ...application.currentPage,
                    pageInfo: page,
                  });
                }
              }, theme.transitions.duration.leavingScreen);
            }}
            key="page-add-dialog"
            testId={!isEmpty(application.currentPage) ? "saveId_btn" : "AddpageId_btn"}
          >
            <AppsPageAdd setPageValues={setPageValues} page={page} application={application} />
          </VfDialog>
          <VfDialog
            title={snippetId ? `Save snippet` : "Add snippet"}
            openDialog={dialogSnippet}
            buttonConfirmText={snippetId ? "Save" : "Add snippet"}
            buttonCloseAction={() => setDialogSnippet(false)}
            buttonConfirmAction={() => {
              if (snippetId) {
                handleSetNameForSnippet(snippetId, snippetName);
              } else {
                handleAddNewSnippet(snippetName);
              }
              setDialogSnippet(false);
            }}
            key="snippet-add-dialog"
          >
            <TextField
              name="title"
              variant="outlined"
              color="primary"
              label="Name"
              type="text"
              value={snippetName}
              onChange={e => setSnippetName(e.target.value)}
              fullWidth
              key="snippet-name"
            />
          </VfDialog>
          <VfDialog
            title={sagaId ? `Save logic` : "Add logic"}
            openDialog={dialogLogic}
            buttonConfirmText={sagaId ? "Save" : "Add logic"}
            buttonCloseAction={() => setDialogLogic(false)}
            buttonConfirmAction={() => {
              if (sagaId) {
                handleSetNameForSaga(sagaId, sagaName);
              } else {
                handleAddNewSaga(sagaName);
              }
              setDialogLogic(false);
            }}
            key="logic-add-dialog"
          >
            <TextField
              name="title"
              variant="outlined"
              color="primary"
              label="Name"
              type="text"
              value={sagaName}
              onChange={e => setSagaName(e.target.value)}
              fullWidth
            />
          </VfDialog>
          {dialogApi && (
            <AddApiDialog
              dialogApi={dialogApi}
              setDialogApi={setDialogApi}
              handleAddNewApi={handleAddNewApi}
              apis={apis}
            />
          )}
          {editApiVersion && (
            <SelectApiVersionDialog
              editApiVersion={editApiVersion}
              setEditApiVersion={setEditApiVersion}
              handleEditApi={handleEditApi}
              apiId={apiId}
            />
          )}
          {dialogPackage && (
            <AddPackageDialog
              dialogPackage={dialogPackage}
              setDialogPackage={setDialogPackage}
              handlePackageConfirm={handleAddNewPackage}
              packages={packages}
            />
          )}
          {editPackageVersion && (
            <AddPackageDialog
              dialogPackage={editPackageVersion}
              setDialogPackage={setEditPackageVersion}
              handlePackageConfirm={handleEditPackage}
              packages={packages}
              packageId={packageId}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    show: state.applications.show,
    categoryWidgets: state.nexusWidgets.data,
    application: state.applications.current,
    snackBar: state.applications.snackBar,
    sidebarItem: state.applications.current.appSideBar,
    isLoading: state.nexusWidgets.loading,
    apis: state.apis.list,
    packages: state.packages.list,
    environment: state.applications.env,
    widgetsUpdating: state.nexusWidgets.updating,
    isDeployment:
      state.applications.current && state.applications.current.appInfo
        ? state.applications.current.appInfo.isDeployed
        : false,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchNexusWidgets: params => dispatch(fetchNexusListWidgets(params)),
  setGridListView: params => dispatch(setNexusWidgetView(params)),
  setShowAddPage: payload => dispatch(setShowAddPageAction(payload)),
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  dispatchSavePage: payload => dispatch(savePage(payload)),
  dispatchSaveAppVersion: payload => dispatch(saveAppVersion(payload)),
  dispatchShowPages: payload => dispatch(showPages(payload)),
  dispatchshowSideBar: payload => dispatch(showSideBar(payload)),
  dispatchShowCreatePage: payload => dispatch(showCreatePage(payload)),
  dispatchShowAddTemplate: payload => dispatch(setShowAddTemplate(payload)),
  dispatchShowSnackBar: payload => dispatch(showSnackbar(payload)),
  getApplications: params => dispatch(fetchApps(params)),
  clearNexusWidgets: () => dispatch(clearNexusWidgetsList()),
  dispatchAddNewSaga: name => dispatch(addNewSaga({ name })),
  dispatchAddNewApi: api => dispatch(addNewApi({ api })),
  dispatchAddNewPackage: (appId, pack) => {
    if (pack.type === "Local") {
      return dispatch(addNewPackage({ pack }));
    }
    return dispatch(resolvePublicPackage(appId, pack));
  },
  dispatchSetNameForSaga: (sagaId, name) => dispatch(setNameOfSaga({ sagaId, name })),
  dispatchRemoveSaga: sagaId => dispatch(removeSaga({ sagaId })),
  dispatchRemoveApi: apiId => dispatch(removeApi({ apiId })),
  dispatchRemovePackage: packageId => dispatch(removePackage({ packageId })),
  dispatchEditApi: api => dispatch(editApi({ api })),

  dispatchEditPackage: pack => dispatch(editPackage({ pack })),
  dispatchAddNewSnippet: name => dispatch(addNewSnippet({ name })),
  dispatchEditSnippet: (snippetId, newSnippet) => dispatch(updateSnippet({ id: snippetId, snippet: newSnippet })),
  dispatchRemoveSnippet: snippetId => dispatch(removeSnippet({ snippetId })),
  clearRowCol: () => dispatch(clearCurrRowCol()),
  clearWidget: () => dispatch(clearSelectedWidget()),
  setSidebarItem: payload => dispatch(setAppSidebar(payload)),
  setAppSection: payload => dispatch(setApplicationSection(payload)),
  setApplication: payload => dispatch(setApp(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsDialog);