import {
  CONFIG_SCHEMA_REQUEST_SUCCESS,
  CONFIG_SCHEMA_REQUEST_FAIL,
  CONFIG_DATA_REQUEST_SUCCESS,
  CONFIG_DATA_REQUEST_FAIL,
  CONFIG_SCHEMA_CLEAR_REQUEST,
  CONFIG_DATA_CLEAR_REQUEST,
  UPDATE_CONFIG_DATA,
  SET_CONFIG_TREE,
  CLEAR_CONFIG_TREE,
  SET_CONFIG_TREE_SELECTED_ITEM,
  CLEAR_CONFIG_TREE_SELECTED_ITEM,
  CONFIG_DATA_POST_REQUEST_SUCCESS,
  CONFIG_DATA_POST_REQUEST_FAIL,
  CLEAR_CONFIG_FORM_ERROR_MESSAGE,
  CLEAR_CONFIG_FORM_FIELDSET,
  DISPATCHING_IS_VALID_DJR,
} from "actions/configFormActions";
import {
  TENANT_SCHEMA_REQUEST_SUCCESS,
  TENANT_SCHEMA_REQUEST_FAIL,
  SAVE_TENANT_REQUEST_SUCCESS,
  SAVE_TENANT_REQUEST_FAIL,
  UPDATE_TENANT_REQUEST_SUCCESS,
  UPDATE_TENANT_REQUEST_FAIL,
  SET_CURRENT_TENANT,
  SET_LANGUAGES_LIST
} from "actions/tenantActions";

const initialState = { postData: false, languageList:[], isValidDJR: true };

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case DISPATCHING_IS_VALID_DJR:
      return {
        ...state,
        isValidDJR: payload
      }
    case SET_LANGUAGES_LIST:
      return {
        ...state,
        languageList: payload,
      };
    case CONFIG_SCHEMA_REQUEST_SUCCESS:
      return {
        ...state,
        schema: payload,
      };
    case CONFIG_SCHEMA_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        schema: null,
      };
    case TENANT_SCHEMA_REQUEST_SUCCESS:
      return {
        ...state,
        schema: payload,
      };    
    case SET_CURRENT_TENANT:
      return {
        ...state,
        data: payload,
      };
    case TENANT_SCHEMA_REQUEST_FAIL:
      return {
        ...state,
        data: null,
        schema: null,
        ...error,
      };
    case SAVE_TENANT_REQUEST_SUCCESS:
      return {
        ...state,
        postData: true,
        error: null,
      };
    case SAVE_TENANT_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case UPDATE_TENANT_REQUEST_SUCCESS:
      return {
        ...state,
        postData: true,
        error: null,
      };
    case UPDATE_TENANT_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case CONFIG_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case CONFIG_DATA_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        data: null,
      };
    case CONFIG_DATA_POST_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case CONFIG_DATA_POST_REQUEST_SUCCESS:
      return {
        ...state,
        postData: true,
        error: null,
      };
    case CLEAR_CONFIG_FORM_ERROR_MESSAGE:
      return {
        ...state,
        postData: false,
        error: null,
      };
    case UPDATE_CONFIG_DATA:
      return {
        ...state,
        data: payload,
      };
    case CONFIG_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null,
      };
    case CONFIG_SCHEMA_CLEAR_REQUEST:
      return {
        ...state,
        schema: null,
      };
    case SET_CONFIG_TREE:
      return {
        ...state,
        tree: payload,
      };
    case CLEAR_CONFIG_TREE:
      return {
        ...state,
        postData: false,
        tree: null,
      };
    case SET_CONFIG_TREE_SELECTED_ITEM:
      return {
        ...state,
        selectedTreeItem: payload,
      };
    case CLEAR_CONFIG_TREE_SELECTED_ITEM:
      return {
        ...state,
        selectedTreeItem: null,
      };
    case CLEAR_CONFIG_FORM_FIELDSET:
      return {
        ...state,
        selectedTreeItem: null,
        postData: false,
        tree: null,
        data: null,
        error: null,
        schema: null,
      };
    default:
      return state;
  }
};
