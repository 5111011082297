/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import { isEmpty } from "lodash";
import CardMedia from "@material-ui/core/CardMedia";
import { connect } from "react-redux";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";
import MenuButton from "./common/MenuButton";
import AppSummary from "./common/AppSummary";
import NewVersionDialog from "./NewVersionDialog";
import { getAppLatestVersion } from "../../actions/applicationsActions";
import { hasPermissions } from "../../utils/permissions-utils";

const appBarHeight = theme.spacing(13);

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    paddingRight: "0 !important",
  },
  toolBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: appBarHeight,
    maxHeight: appBarHeight,
  },
  gridDivider: {
    alignSelf: "stretch",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const AppHeader = ({
  application,
  onEdit,
  onClose,
  onSave,
  onSaveVersion,
  permissions,
  applyPermissions,
  isValidDJR,
}) => {
  const classes = useStyles();

  const [versionDialogOpen, setVersionDialogOpen] = useState(false);

  const isNewAppVersionAllowed = () => {
    return (
      !application?.appInfo?.isDeployed &&
      hasPermissions(
        permissions || [],
        ["create_app_version"],
        applyPermissions
      )
    );
  };

  const SaveButton = () => {
    if (isNewAppVersionAllowed()) {
      return (
        <MenuButton
          items={[
            { id: 1, label: "Save" },
            { id: 2, label: "Save as new version" },
          ]}
          disabled={
            isEmpty(application.currentPage) || !application.currentPage.djr || !isValidDJR
          }
          onClick={id => {
            // eslint-disable-next-line no-unused-expressions,no-underscore-dangle
            id === 1 ? onSave() : setVersionDialogOpen(true);
          }}
          onClose={() => {}}
        />
      );
    }
    return (
      <Button
        // autoFocus
        color="secondary"
        variant="contained"
        disabled={
          isEmpty(application.currentPage) || !application.currentPage.djr
        }
        onClick={onSave}
      >
        Save
      </Button>
    );
  };

  return (
    <AppBar color="inherit" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs="auto">
                {/* eslint-disable */}
                {application.appInfo.iconBase64 && (
                  <CardMedia image={application.appInfo.iconBase64} />
                )}
                {!application.appInfo.iconBase64 && (
                  <VfSvgIcon icon="vodafone" viewBox={24} />
                )}
                {/* eslint-enable */}
              </Grid>
              <Grid item xs="auto" className={classes.gridDivider}>
                <Divider orientation="vertical" />
              </Grid>
              <AppSummary
                appTitle={application.appInfo.title}
                appType={application.appInfo.appType}
                appPlatform={application.appInfo.platform}
                appTheme={application.appInfo.theming}
                appBgColor={application.appInfo.bgColor}
                appTags={application.appInfo.tags}
                appVersion={application.appInfo.version}
                unmanaged={application.appInfo.unmanaged}
                hasAuth={application.appInfo.hasAuth}
                onEditApp={onEdit}
                editBtnId="editApp_btn"
              />
            </Grid>
          </Grid>
          <Grid item xs="auto" className={classes.gridDividerExpand}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs="auto">
            <Button
              // autoFocus
              color="default"
              variant="outlined"
              onClick={onClose}
              data-testid="closeAppDialog_btn"
            >
              Close
            </Button>
          </Grid>

          <Grid item xs="auto">
            <SaveButton />
          </Grid>
        </Grid>
      </Toolbar>
      <NewVersionDialog
        isOpen={versionDialogOpen}
        application={application.appInfo}
        onCancel={() => setVersionDialogOpen(false)}
        onConfirm={(version, description) => {
          setVersionDialogOpen(false);
          onSaveVersion(version, description);
        }}
      />
    </AppBar>
  );
};

const mapStateToProps = state => {
  return {
    permissions: state.userPermissions.permissions,
    applyPermissions: state.userPermissions.enablePermissions,
    isValidDJR: state.configForm.isValidDJR,
  };
};

const mapDispatchToProps = dispatch => ({
  getApplicationLatestVersion: params => dispatch(getAppLatestVersion(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
