/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual, connect } from "react-redux";
import {
  CardMedia,
  Chip,
  Divider,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";

import {
  clearConfigTree as clearingConfigTree,
  clearSelectedTreeItem as clearingSelectedTreeItem,
  postConfigData,
  clearConfigErrorMessage,
  clearConfigData,
  clearConfigSchema,
} from "actions/configFormActions";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfDialogFull from "../vf/VfDialogFull";
import EditorForm from "../editor/EditorForm";
import VfAlert from "../vf/VfAlert";

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  splitButton: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

const DialogConfiguratorWidget = ({ app, dialogWidget, setDialogWidget, isValidDJR }) => {  
  const { data } = useSelector(state => state.configForm, shallowEqual);
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const validations = useSelector(
    state => state.configForm.error,
    shallowEqual
  );
  const postDataSuccess = useSelector(
    state => state.configForm.postData,
    shallowEqual
  );

  const currentWidgetId = useSelector(
    state => state.configuration.currentWidget.componentId,
    shallowEqual
  );

  const currentWidgetInstance = useSelector(
    state => state.configuration.currentWidget.widgetInstanceId,
    shallowEqual
  );

  const dispatch = useDispatch();
  const clearValidations = () => dispatch(clearConfigErrorMessage());
  const clearConfigTree = () => dispatch(clearingConfigTree());
  const clearSelectedTreeItem = () => dispatch(clearingSelectedTreeItem());
  const onSubmitConfigData = parmas => dispatch(postConfigData(parmas));
  const clearData = () => dispatch(clearConfigData());
  const clearSchema = () => dispatch(clearConfigSchema());

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveAndDeploy, setSaveAndDeploy] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (postDataSuccess) {
      setDialogWidget(false);
      clearConfigTree();
      clearSelectedTreeItem();
      clearData();
      clearSchema();
    }
  }, [postDataSuccess]);

  useEffect(() => {
    if (validations && validations.length) {
      setOpenSnackbar(true);
    }
  }, [validations]);

  const changeModeClick = mode => {
    setSaveAndDeploy(mode);
    // return changeMode && changeMode(mode);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <VfDialogFull
      /* eslint-disable */
      appBar={
        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={12} sm="auto">
            {/* eslint-disable */}
            {app.iconBase64 && <CardMedia image={app.iconBase64} />}
            {!app.iconBase64 && <VfSvgIcon icon="vodafone" viewBox={24} />}
            {/* eslint-enable */}
          </Grid>
          <Divider flexItem orientation="vertical" variant="fullWidth" />
          <Grid item xs={12} sm data-testid="appDialogConfiguration_titlt">
            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Typography variant="body1" component="h5">
                  {app.title}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography variant="body1" component="span" color="secondary">
                  {app.version}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <VfSvgIcon
                  icon={app.appType}
                  viewBox={24}
                  className="MuiSvgIcon-fontSizeXSmall"
                />
              </Grid>
              <Grid item xs="auto">
                <VfSvgIcon
                  icon={app.platform}
                  viewBox={24}
                  className="MuiSvgIcon-fontSizeXSmall"
                />
              </Grid>
              <Grid item xs="auto">
                <Typography variant="body2" color="textSecondary">
                  {app.theming}
                </Typography>
              </Grid>
            </Grid>
            {app.tags.length > 0 && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {app.tags ? app.tags.join(", ") : ""}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {app.status && (
            <Grid item xs={12} sm="auto">
              <Typography variant="body2" component="div">
                {app.status}
              </Typography>
            </Grid>
          )}
          <Grid item xs="auto">
            <Chip
              /* eslint-disable */
              icon={
                <VfSvgIcon
                  className="MuiChip-leftIcon"
                  icon={app.envType}
                  fontSize="small"
                />
              }
              /* eslint-enable */
              label={app.envTitle}
            />
          </Grid>
        </Grid>
      }
      /* eslint-enable */
      buttonConfirmGroup={
        /* eslint-disable */
        <Grid item xs="auto">
          <ButtonGroup
            variant="contained"
            color="secondary"
            className={classes.button}
            data-testid="save_btn_group"
            disabled={!isValidDJR}
          >
            <Button
              className={classes.flex}
              onClick={() => {
                onSubmitConfigData({
                  data,
                  widget: currentWidgetId || "global",
                  widgetInstanceId: currentWidgetInstance || null,
                  // eslint-disable-next-line no-underscore-dangle
                  app: app._id,
                  id_token,
                  access_token,
                  envID: app.envId,
                  deploy: app.isGlobal || saveAndDeploy,
                });
              }}
              data-testid="save_btn_text"
            >
              {saveAndDeploy ? "Save & Deploy" : "Save"}
            </Button>

            {!app.isGlobal?<Button
              className={classes.splitButton}
              aria-label="add to favorites"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              data-testid="save_btn_splitIcon"
            >
              <VfSvgIcon className="MuiSvgIcon-white" icon="chevronDown" testid="save_btn_group"/>
            </Button>:null}
          </ButtonGroup>
          {!app.isGlobal ? <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={e => {
                changeModeClick(true);
                handleClose(e);
              }}
            >
              SAVE AND DEPLOY
            </MenuItem>
            <MenuItem
              onClick={e => {
                changeModeClick(false);
                handleClose(e);
              }}
            >
              SAVE
            </MenuItem>
          </Menu>:null}
        </Grid>
        /* eslint-enable */
      }
      buttonConfirmDisabled={false}
      buttonConfirmAction={() => {}}
      buttonCloseAction={() => {
        setDialogWidget(false);
        clearData();
        clearValidations();
        clearConfigTree();
        clearSelectedTreeItem();
        clearSchema();
      }}
      openDialog={dialogWidget}
    >
      <EditorForm isGlobal={app.isGlobal}/>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      >
        <VfAlert severity={0} message={validations?.map(v => v.instance + " " + v.message)} />
      </Snackbar>
    </VfDialogFull>
  );
};

const mapStateToProps = state => {
  return {
    isValidDJR: state.configForm.isValidDJR,
  };
};

export default connect(mapStateToProps, null)(DialogConfiguratorWidget);